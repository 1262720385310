import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/acorn-white.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/acorn.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/ai-lesson.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Arrow-down-white.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Arrow-down.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Arrow-left-white.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Arrow-left.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Arrow-right-white.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Arrow-right.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Arrow-up-white.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Arrow-up.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Bell-white.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Bell.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Chevron-down-white.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Chevron-down.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Chevron-left-white.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Chevron-left.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Chevron-right-white.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Chevron-right.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Chevron-up-white.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Chevron-up.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Copy-white.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Copy.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Cross-white.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Cross.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Download-white.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Download.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Equipment-white.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Equipment.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/External-white.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/External.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Facebook-white.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Facebook.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Globe-white.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Globe.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Hamburger-white.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Hamburger.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Home-white.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Home.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Instagram-white.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Instagram.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/LinkedIn-white.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/LinkedIn.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Mini-menu-white.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Mini-menu.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Padlock.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Print-white.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Print.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Question-mark-white.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Question-mark.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/reload-white.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/reload.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Save-white.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Save.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Search-white.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Search.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Send-white.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Send.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Share-white.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Share.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Sidebar-white.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Sidebar.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/splodge.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Star-filled-white.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Star-filled.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Tick-white.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Tick.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Twitter-white.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Twitter.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/upload-white.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/upload.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Warning-white.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/assets/svg/Warning.svg");
;
import(/* webpackMode: "eager", webpackExports: ["AccessibleIcon"] */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/accessible-icon.js");
;
import(/* webpackMode: "eager", webpackExports: ["AlertDialog","AlertDialogRoot","AlertDialogTrigger","AlertDialogContent","AlertDialogTitle","AlertDialogDescription","AlertDialogAction","AlertDialogCancel"] */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/alert-dialog.js");
;
import(/* webpackMode: "eager", webpackExports: ["AspectRatio"] */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/aspect-ratio.js");
;
import(/* webpackMode: "eager", webpackExports: ["Avatar"] */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/avatar.js");
;
import(/* webpackMode: "eager", webpackExports: ["Callout","CalloutRoot","CalloutIcon","CalloutText"] */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/callout.js");
;
import(/* webpackMode: "eager", webpackExports: ["Checkbox"] */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/checkbox.js");
;
import(/* webpackMode: "eager", webpackExports: ["ContextMenu","ContextMenuRoot","ContextMenuTrigger","ContextMenuContent","ContextMenuLabel","ContextMenuItem","ContextMenuGroup","ContextMenuRadioGroup","ContextMenuRadioItem","ContextMenuCheckboxItem","ContextMenuSub","ContextMenuSubTrigger","ContextMenuSubContent","ContextMenuSeparator"] */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/context-menu.js");
;
import(/* webpackMode: "eager", webpackExports: ["Dialog","DialogRoot","DialogTrigger","DialogContent","DialogTitle","DialogDescription","DialogClose"] */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/dialog.js");
;
import(/* webpackMode: "eager", webpackExports: ["DropdownMenu","DropdownMenuRoot","DropdownMenuTrigger","DropdownMenuContent","DropdownMenuLabel","DropdownMenuItem","DropdownMenuGroup","DropdownMenuRadioGroup","DropdownMenuRadioItem","DropdownMenuCheckboxItem","DropdownMenuSub","DropdownMenuSubTrigger","DropdownMenuSubContent","DropdownMenuSeparator"] */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/dropdown-menu.js");
;
import(/* webpackMode: "eager", webpackExports: ["HoverCard","HoverCardRoot","HoverCardTrigger","HoverCardContent"] */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/hover-card.js");
;
import(/* webpackMode: "eager", webpackExports: ["Popover","PopoverRoot","PopoverTrigger","PopoverContent","PopoverClose"] */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/popover.js");
;
import(/* webpackMode: "eager", webpackExports: ["Portal"] */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/portal.js");
;
import(/* webpackMode: "eager", webpackExports: ["RadioGroup","RadioGroupRoot","RadioGroupItem"] */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/radio-group.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/scroll-area.js");
;
import(/* webpackMode: "eager", webpackExports: ["Select","SelectRoot","SelectTrigger","SelectContent","SelectItem","SelectGroup","SelectLabel","SelectSeparator"] */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/select.js");
;
import(/* webpackMode: "eager", webpackExports: ["Separator"] */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/separator.js");
;
import(/* webpackMode: "eager", webpackExports: ["Slider"] */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/slider.js");
;
import(/* webpackMode: "eager", webpackExports: ["Switch"] */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/switch.js");
;
import(/* webpackMode: "eager", webpackExports: ["Tabs","TabsRoot","TabsList","TabsTrigger","TabsContent"] */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/tabs.js");
;
import(/* webpackMode: "eager", webpackExports: ["TextField","TextFieldRoot","TextFieldSlot","TextFieldInput"] */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/text-field.js");
;
import(/* webpackMode: "eager", webpackExports: ["Tooltip"] */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/tooltip.js");
;
import(/* webpackMode: "eager", webpackExports: ["VisuallyHidden"] */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/visually-hidden.js");
;
import(/* webpackMode: "eager", webpackExports: ["ThemePanel"] */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/theme-panel.js");
;
import(/* webpackMode: "eager", webpackExports: ["Theme","useThemeContext","updateThemeAppearanceClass"] */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/theme.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
